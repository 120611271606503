import React from "react";
import logo from "../images/flame.svg";
import { Link } from "gatsby";

function Footer() {
  return (
    <footer>
      <div class="container">
        <div class="twelve columns footer">
          <div class="copyright">
            Copyright © 2010 - {new Date().getFullYear()} &nbsp;
            <Link to="/">Enlightened Pixel LLC</Link>
            &nbsp; All rights reserved.
          </div>
          <br />
          <img className="logo-small" src={logo} alt="EnlightenedPixel Logo" />
        </div>
      </div>
    </footer>
  );
}
export default Footer;
