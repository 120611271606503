import React from "react";
import Helmet from "react-helmet";
import Header from "./header";
import Footer from "./footer";

class Layout extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <div>
        <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            href="https://fonts.googleapis.com/css?family=Open+Sans:300"
          ></link>
        </Helmet>
        <Header />
        <div className="wrapper-content container">
          <div class="twelve columns content">{children}</div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Layout;
